import React from "react";
import { Container, Navbar } from "react-bootstrap";

const Footer = () => {
  var date = new Date();
  var fullYear = date.getFullYear();
  return (
    <Navbar bg="primary" expand="md" className="text-light">
      <Container fluid>
        <small>ERM</small>
        <small>Copyright © {fullYear}</small>
      </Container>
    </Navbar>
  );
};

export default Footer;
