import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { get } from "utils/DeApi";

import ProductDelete from "../ProductDelete/ProductDelete";
import ProductUpdate from "../ProductUpdate/ProductUpdate";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import AssessmentSummary from "components/Assessment/AssessmentSummary/AssessmentSummary";
import { OrganizationContext } from "contexts/OrganizationProvider";

const ProductDetails = ({ productId }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [product, setProduct] = useState();
  const [assessments, setAssessments] = useState();

  const organization = useContext(OrganizationContext);
  let history = useHistory();

  const handleProductDeleted = () => {
    history.push("/");
  };

  useEffect(() => {
    const fetchProduct = () => {
      setError("");
      setIsLoading(true);
      const productPromise = get(`products/${productId}`);
      productPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);

          const product = response.data;
          if (product.organizationId !== organization.categoryId)
            history.push("/");
          else {
            setProduct(product);
          }
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });
      subscribedPromises.current.push(productPromise);
    };

    fetchProduct();
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [productId, organization, history]);

  useEffect(() => {
    const fetchAssessments = () => {
      setError("");
      setIsLoading(true);

      const assessmentsPromise = get(`products/${productId}/assessments`);
      assessmentsPromise.promise
        .then((response) => {
          const assessments = response.data;
          setIsLoading(false);
          setError(null);
          setAssessments(assessments);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });
      subscribedPromises.current.push(assessmentsPromise);
    };
    fetchAssessments();
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [productId]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  if (!product) return <span />;
  return (
    <div className="mt-3">
      <span className="float-end">
        <ProductUpdate
          product={product}
          onProductUpdated={(updatedProduct) => setProduct(updatedProduct)}
        />{" "}
        <ProductDelete
          product={product}
          onProductDeleted={handleProductDeleted}
        />
      </span>
      <h1>{product.name}</h1>
      <p>Last updated at {new Date(product.updatedAt).toLocaleString()}</p>

      <Card className="mt-3 mb-3">
        <Card.Body>
          <h4>Assessments</h4>
          <hr />

          {assessments && !assessments.length && (
            <h4 className="mt-3 mb-3">
              There are currently no assessments to show.
            </h4>
          )}

          {assessments &&
            assessments.map((assessment) => (
              <span key={assessment.assessmentId}>
                <div className="d-flex flex-row">
                  <div className="flex-grow-1">
                    <h4>
                      <Link
                        to={`/assessments/${assessment.assessmentId}`}
                        size="sm"
                        className="text-decoration-none bold"
                        variant="secondary"
                      >
                        {assessment.name}
                      </Link>
                    </h4>
                    <p className="mb-2">
                      <span className="me-3">
                        <span className="material-icons md-18 text-muted">
                          sell
                        </span>{" "}
                        <Link
                          className="text-decoration-none text-dark"
                          to={`/products/${assessment.product.productId}`}
                        >
                          {assessment.product.name}
                        </Link>
                      </span>

                      <span className="me-3">
                        <span className="material-icons md-18 text-muted">
                          policy
                        </span>{" "}
                        <span>{assessment.lens.title}</span>
                      </span>
                    </p>
                    <p>
                      <small>
                        Last updated at{" "}
                        {new Date(assessment.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </small>
                    </p>
                  </div>
                  <div className="float-end">
                    {
                      <AssessmentSummary
                        assessmentId={assessment.assessmentId}
                        lensId={assessment.lens.lensId}
                      />
                    }
                  </div>
                </div>
                <hr />
              </span>
            ))}
        </Card.Body>
      </Card>
    </div>
  );
};

ProductDetails.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default ProductDetails;
