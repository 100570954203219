import React, { useContext } from "react";

import Header from "components/App/Header/Header";
import { Card, Col, Container, Row } from "react-bootstrap";
import { OrganizationContext } from "contexts/OrganizationProvider";
import AssessmentsList from "components/Assessment/AssessmentsList/AssessmentsList";
import Footer from "components/App/Footer/Footer";

const Dashboard = () => {
  const organization = useContext(OrganizationContext);

  return (
    <>
      <Header title="Assessments " />
      <Container className="mt-3 mb-3 MainContent ">
        <Row>
          <Col xs={12} className="mt-3 mb-3">
            <Card className="mt-3 mb-3">
              <Card.Body>
                <AssessmentsList organization={organization} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
