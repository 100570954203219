import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik } from "formik";

import { Modal, Button, Form } from "react-bootstrap";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const ProductUpdate = ({ product, onProductUpdated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(50, "Too Long!").required(),
    description: yup.string(),
  });

  const updateProduct = (values) => {
    setError("");
    setIsLoading(true);

    const productPromise = put(`products/${product.productId}`, {
      name: values.name,
      description: values.description,
    });

    productPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();

        onProductUpdated(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(productPromise);
  };

  return (
    <>
      <Button variant="secondary" size="sm" onClick={handleShow}>
        Update
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Product</Modal.Title>
        </Modal.Header>

        {product && (
          <Formik
            validationSchema={schema}
            onSubmit={updateProduct}
            initialValues={{
              name: product.name,
              description: product.description || "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              isValid,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Group controlId="productName" className="mt-3 mb-3">
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && values.name && !errors.name}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="productDescription"
                    className="mt-3 mb-3"
                  >
                    <Form.Label>
                      Product Description <small>Optional</small>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows={2}
                    />
                  </Form.Group>
                  {error && <ErrorHandler error={error} />}
                  {isLoading && <Loader />}
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={!values.name || !isValid}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

ProductUpdate.propTypes = {
  product: PropTypes.object.isRequired,
  onProductUpdated: PropTypes.func.isRequired,
};

export default ProductUpdate;
