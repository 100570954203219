import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="text-center Logo">
    <Link to="/">
      <img className="img-responsive" src="/logo.svg" width="144px" alt="ERM" />
      <h2 className="my-3">ERMClarity</h2>
    </Link>
  </div>
);

export default TextLogo;
