import React, { useState, useEffect, useRef } from "react";
import "./AssessmentDetails.scss";
import PropTypes from "prop-types";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Col, Row, Dropdown } from "react-bootstrap";
import { get } from "utils/DeApi";

import NoMatch from "components/App/NoMatch/NoMatch";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import Question from "../Question/Question";
import AssessmentReport from "../AssessmentReport/AssessmentReport";
import SectionNavigation from "./SectionNavigation/SectionNavigation";

const AssessmentDetails = ({ assessmentId, organization }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [assessment, setAssessment] = useState();
  const [lens, setLens] = useState();
  const [principles, setPrinciples] = useState();
  const [selectedPrinciple, setSelectedPrinciple] = useState();

  const history = useHistory();

  const handleQuestionAnswered = (questionResponse) => {
    setAssessment((prevAssessment) => {
      let questionId = questionResponse.questionId;
      let qResponse = assessment.responses.find(
        (item) => item.questionId === questionId
      );
      if (qResponse)
        return {
          ...prevAssessment,
          ...{
            responses: prevAssessment.responses.map((item) => {
              if (item.questionId === questionId) return questionResponse;
              return item;
            }),
          },
        };
      else
        return {
          ...prevAssessment,
          ...{
            responses: [...prevAssessment.responses, ...[questionResponse]],
          },
        };
    });
  };

  const findNextAndPreviousQuestions = (sectionId, questionId) => {
    const currentSection = lens.sections.find(
      (item) => item.sectionId === sectionId
    );
    const currPrinciple = currentSection.principles.find(
      (item) => item.principleId === selectedPrinciple.principleId
    )
      ? selectedPrinciple
      : currentSection.principles[0];
    if (currPrinciple.principleId !== selectedPrinciple.principleId)
      setSelectedPrinciple(currPrinciple);

    const currentSections = lens.sections.filter((section) =>
      section.principles.find(
        (principle) => principle.principleId === currPrinciple.principleId
      )
    );

    const sectionIndex = currentSections.findIndex(
      (section) => section.sectionId === sectionId
    );

    const questionIndex = currentSections[sectionIndex].questions.findIndex(
      (question) => question.questionId === questionId
    );

    let previousQuestion =
      currentSections[sectionIndex].questions[questionIndex - 1];

    let previousSectionId = sectionId;
    if (!previousQuestion) {
      let previousSection = currentSections[sectionIndex - 1];
      if (previousSection) {
        previousQuestion =
          previousSection.questions[previousSection.questions.length - 1];
        previousSectionId = previousSection.sectionId;
      }
    }

    let nextQuestion =
      currentSections[sectionIndex].questions[questionIndex + 1];
    let nextSectionId = sectionId;
    if (!nextQuestion) {
      let nextSection = currentSections[sectionIndex + 1];
      if (nextSection) {
        nextQuestion = nextSection.questions[0];
        nextSectionId = nextSection.sectionId;
      }
    }

    let next = nextQuestion
      ? `/assessments/${assessmentId}/sections/${nextSectionId}/questions/${nextQuestion.questionId}`
      : "";

    let previous = previousQuestion
      ? `/assessments/${assessmentId}/sections/${previousSectionId}/questions/${previousQuestion.questionId}`
      : "";

    return { next: next, previous: previous };
  };

  useEffect(() => {
    const findSectionsAndPrinciples = (sections, responses) => {
      var sectionsPrinciples = [];
      var sectionByPrinciple = {};

      sections = sections.map((section) => {
        section.principles.forEach((p) => {
          sectionByPrinciple[p.principleId] = sectionByPrinciple[p.principleId]
            ? [...sectionByPrinciple[p.principleId], ...[section]]
            : [section];

          if (
            !sectionsPrinciples.find(
              (item) => item.principleId === p.principleId
            )
          )
            sectionsPrinciples = [...sectionsPrinciples, ...[p]];
        });

        section["score"] = 0;
        section["maxScore"] = 0;
        section.questions.forEach((question) => {
          const qResponse = responses.find(
            (response) => response.questionId === question.questionId
          );

          const weight = question.weight || 1;
          section["score"] +=
            qResponse && qResponse.isInapplicable
              ? 0
              : qResponse && qResponse.answerId
              ? qResponse.score * weight
              : 0;

          section["maxScore"] +=
            qResponse && qResponse.isInapplicable
              ? 0
              : Math.max(...question.answers.map((answer) => answer.score)) *
                weight;
        });

        return section;
      });

      sectionsPrinciples = sectionsPrinciples
        .map((principle) => {
          let pSections = sectionByPrinciple[principle.principleId];
          principle["score"] = 0;
          principle["maxScore"] = 0;

          pSections.forEach((section) => {
            principle["score"] += section.score;
            principle["maxScore"] += section.maxScore;
          });
          return principle;
        })
        .reverse();
      sectionsPrinciples.sort((a, b) => (a.updatedAt <= b.updatedAt ? 1 : -1));
      var selectedP =
        sectionsPrinciples.find((principle) =>
          principle.title.toLowerCase().includes("lifecycle")
        ) || sectionsPrinciples[0];

      setPrinciples(sectionsPrinciples);
      setSelectedPrinciple(selectedP);
    };

    const fetchLens = (assessment) => {
      setError("");
      setIsLoading(true);
      var lens = assessment.lens;
      const lensPromise = get(`lenses/${lens.lensId}`);
      lensPromise.promise
        .then((response) => {
          let assessmentLens = response.data;
          setIsLoading(false);
          setError(null);
          findSectionsAndPrinciples(
            assessmentLens.sections,
            assessment.responses
          );

          setLens(assessmentLens);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(lensPromise);
    };

    const fetchAssessment = () => {
      setError("");
      setIsLoading(true);

      const assessmentPromise = get(`assessments/${assessmentId}`);
      assessmentPromise.promise
        .then((response) => {
          const assessment = response.data;
          const product = assessment.product;
          if (product.organizationId !== organization.categoryId)
            history.push("/");
          else {
            setAssessment(assessment);
            fetchLens(assessment);
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(assessmentPromise);
    };

    fetchAssessment();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [assessmentId, organization, history]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!assessment || !lens || !principles) return <span />;

  return (
    <Row>
      <Col xs={12} md={3} className="scroller">
        <div className="mt-4 mb-5">
          <h3>
            <Link
              className="text-decoration-none mb-0 fw-bold"
              to={`/products/${assessment.product.productId}`}
            >
              {assessment.product.name}
            </Link>
          </h3>
          <h4>{assessment.name}</h4>
          <p>{lens.name}</p>
          {/* Reason of using the javascript window.location.href is 
          that the Link which is not updating the DOM and just render the current component
          now the window.location will directly update the history so we can get the updated Principles Score */}
          <h4>
            <Link
              className="text-decoration-none"
              to=""
              onClick={() => {
                window.location.href = `/assessments/${assessment.assessmentId}`;
              }}
            >
              Summary
            </Link>
          </h4>
          <hr />

          {selectedPrinciple && (
            <Dropdown className="mb-2">
              <Dropdown.Toggle className="bg-primary bg-opacity-10 rounded-3 text-dark border-0">
                {selectedPrinciple.title.length > 22
                  ? selectedPrinciple.title.substring(0, 23) + "..."
                  : selectedPrinciple.title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {principles.map((principle) => (
                  <Dropdown.Item
                    key={principle.principleId}
                    onClick={() => {
                      setSelectedPrinciple(principle);
                      let pSection = lens.sections.find((section) =>
                        section.principles.find(
                          (p) => p.principleId === principle.principleId
                        )
                      );
                      let pQuestion = pSection.questions[0];

                      if (pSection && pQuestion)
                        history.push(
                          `/assessments/${assessmentId}/sections/${pSection.sectionId}/questions/${pQuestion.questionId}`
                        );
                      else history.push(`/assessments/${assessmentId}`);
                    }}
                    active={
                      principle.principleId === selectedPrinciple.principleId
                    }
                  >
                    {principle.title.length > 30
                      ? principle.title.substring(0, 35) + "..."
                      : principle.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {lens.sections
            .filter((section) =>
              section.principles.find(
                (principle) =>
                  principle.principleId === selectedPrinciple.principleId
              )
            )
            .map((section) => (
              <SectionNavigation
                key={section.sectionId}
                section={section}
                assessment={assessment}
              />
            ))}
        </div>
      </Col>
      <Col xs={12} md={9}>
        <Switch>
          <Route
            exact
            path="/assessments/:id"
            component={() => (
              <AssessmentReport
                lens={lens}
                assessment={assessment}
                principles={principles}
              />
            )}
          />
          <Route
            exact
            path="/assessments/:id/sections/:sectionId/questions/:questionId"
            render={(props) => {
              let sectionId = props.match.params.sectionId;
              let questionId = props.match.params.questionId;
              let assessmentId = props.match.params.id;

              return (
                <Question
                  questionId={questionId}
                  assessmentId={assessmentId}
                  sectionId={sectionId}
                  onAnswered={handleQuestionAnswered}
                  findNextAndPrevious={findNextAndPreviousQuestions}
                />
              );
            }}
          />
          <Route component={NoMatch} />
        </Switch>
      </Col>
    </Row>
  );
};

AssessmentDetails.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
};

export default AssessmentDetails;
