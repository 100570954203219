import React, { useEffect, useRef, useState, useContext } from "react";
import { Card } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { Link } from "react-router-dom";

const AllProducts = () => {
  const subscribedPromises = useRef([]);
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    const fetchProducts = () => {
      setError("");
      setIsLoading(true);
      const productsPromise = get("products", {
        params: { organizationId: organization.categoryId },
      });
      productsPromise.promise
        .then((response) => {
          const products = response.data;
          setIsLoading(false);
          setError(null);
          setProducts(products);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(productsPromise);
    };

    fetchProducts();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Card className="mt-3 mb-3 ">
          <Card.Body>
            <h4>Products</h4>
            <hr />

            <span>
              {products?.map((prd) => (
                <div key={prd.productId}>
                  <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                      <span className="d-flex justify-content-between mb-n4">
                        <h4>
                          <Link
                            to={`/products/${prd?.productId}`}
                            size="sm"
                            className="text-decoration-none bold"
                            variant="secondary"
                          >
                            {prd?.name}
                          </Link>
                        </h4>
                        <span className="d-flex flex-column fs-6 me-5">
                          <p className="mb-2">Assessments</p>
                          <strong className="text-center fs-5">
                            {prd?.assessmentsCount_count || 0}
                          </strong>
                        </span>
                      </span>
                      <div className="fs-6">
                        <p className="mb-2 wd-65">
                          Description:{" "}
                          {prd?.description ||
                            " Some quick example text for product description "}
                        </p>

                        <p>
                          <small>
                            Created At:{" "}
                            {new Date(prd?.createdAt).toLocaleString([], {
                              dateStyle: "short",
                              timeStyle: "short",
                            })}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              ))}
            </span>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default AllProducts;
