import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik } from "formik";

import { Modal, Button, Form } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const ProductDelete = ({ product, onProductDeleted }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    productName: yup.string().required(),
  });

  const deleteProduct = (values) => {
    setError("");
    setIsLoading(true);

    const productPromise = destroy(`products/${product.productId}`);

    productPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onProductDeleted(product);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(productPromise);
  };

  return (
    <>
      <Button variant="outline-secondary" size="sm" onClick={handleShow}>
        Delete
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Product <i>{product.name}</i>
          </Modal.Title>
        </Modal.Header>
        {error && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
        {product && (
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              deleteProduct(values);
            }}
            initialValues={{
              productName: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <p>
                    Are you sure you want to delete this product assessment?
                  </p>

                  <Form.Group controlId="productName" className="mt-3 mb-3">
                    <Form.Label>
                      Please type the name of the product to confirm.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="productName"
                      value={values.productName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        values.productName === product.name &&
                        !errors.productName
                      }
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    variant="danger"
                    disabled={
                      values.productName.trim() !== product.name || !isValid
                    }
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

ProductDelete.propTypes = {
  product: PropTypes.object.isRequired,
  onProductDeleted: PropTypes.func.isRequired,
};

export default ProductDelete;
