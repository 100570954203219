import React from "react";
import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import "./ErrorHandler.scss";

const ErrorHandler = ({ error, message, className = "" }) => {
  let status = "";
  let statusText = "Oh snap! You got an error!";
  let _message = "Something unexpected happened. Please try again.";
  let location = useLocation();

  if (error) {
    status = error.status || status;
    statusText = error.statusText || statusText;

    if (status === 401 && location.pathname !== "/login") {
      window.location.replace("/login");
    }

    if (error.data && error.data.error) {
      if ("message" in error.data.error) {
        _message = error.data.error.message;
      } else {
        _message =
          error.data.error[Object.keys(error.data.error)[0]] ?? _message;
      }
    }
  }
  return (
    <Alert variant="warning" className={`mt-3 mb-3 ${className}`}>
      <h4>{statusText}</h4>
      <p>{message || _message}</p>
      <small>{status}</small>
    </Alert>
  );
};

export default ErrorHandler;
