import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useFormikContext, Formik, Field } from "formik";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import { debounce } from "lodash";

import { get, post } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { Link } from "react-router-dom";
import * as yup from "yup";

const Question = ({
  questionId,
  assessmentId,
  onAnswered,
  findNextAndPrevious,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [question, setQuestion] = useState();
  const [questionResponse, setQuestionResponse] = useState();
  const [next, setNext] = useState();
  const [previous, setPrevous] = useState();
  let NotesCharacterCount = 500;

  const params = useParams();

  const schema = yup.object().shape({
    notes: yup.string().max(500, "Notes should not exceed 500 characters!"),
  });

  useEffect(() => {
    const fetchQuestionResponse = () => {
      setError("");
      setIsLoading(true);

      const questionResponsePromise = get(
        `assessments/${assessmentId}/responses`,
        {
          params: { questionId: questionId },
        }
      );
      questionResponsePromise.promise
        .then((response) => {
          const responses = response.data;
          setIsLoading(false);
          setError(null);
          setQuestionResponse(responses[0] || {});
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });
      subscribedPromises.current.push(questionResponsePromise);
    };

    const fetchQuestion = () => {
      setError("");
      setIsLoading(true);

      const questionPromise = get(`questions/${questionId}`);
      questionPromise.promise
        .then((response) => {
          const question = response.data;
          setIsLoading(false);
          setError(null);
          setQuestion(question);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });
      subscribedPromises.current.push(questionPromise);
    };

    setQuestionResponse(null);
    setQuestion(null);
    fetchQuestion();
    fetchQuestionResponse();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [questionId, assessmentId]);

  useEffect(() => {
    let { next, previous } = findNextAndPrevious(
      params.sectionId,
      params.questionId
    );

    setNext(next);
    setPrevous(previous);
  }, [params, findNextAndPrevious]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  if (!question || !questionResponse) return <span />;

  return (
    <Row>
      <Col xs={12} md={8}>
        <div className="mt-4 mb-5">
          <Formik
            validationSchema={schema}
            initialValues={{
              selectedAnswer: questionResponse.answerId || "",
              isInapplicable: questionResponse.isInapplicable || false,
              notes: questionResponse.notes || "",
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              values,
              errors,
              isValid,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <Card.Header>
                    <Card.Title className="mt-3 mb-3">
                      {question.title}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p>{question.description}</p>
                    <div className="mt-3 mb-3">
                      <Field
                        as={Form.Switch}
                        onChange={handleChange}
                        checked={values.isInapplicable}
                        value={values.isInapplicable}
                        type="switch"
                        name="isInapplicable"
                        label="Question does not apply to this product"
                      />
                    </div>
                    <p className="mt-3 mb-3">
                      Select one from the following
                      <small className="float-end">Score</small>
                    </p>
                    <hr />
                    {question.answers
                      .sort((a, b) => b.score - a.score)
                      .map((answer) => {
                        return (
                          <div className="mt-3 mb-3" key={answer.answerId}>
                            <small className="float-end">
                              {Math.round(answer.score)}
                            </small>

                            <Field
                              as={Form.Check}
                              onChange={handleChange}
                              type="radio"
                              name="selectedAnswer"
                              value={answer.answerId}
                              className="wd-85"
                              label={answer.description}
                              disabled={values.isInapplicable}
                            />
                          </div>
                        );
                      })}
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="px-4 py-0"
                      onClick={() => setFieldValue("selectedAnswer", "")}
                      disabled={values.isInapplicable || !values.selectedAnswer}
                    >
                      Clear Selection
                    </Button>
                    <hr />
                    <Form.Group controlId="anwer-notes" className="mt-3 mb-3">
                      <Form.Label>
                        Notes{" "}
                        <small>
                          <i>Optional</i>
                        </small>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="notes"
                        placeholder="You may enter your notes and documentation here."
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={3}
                        isInvalid={!!errors.notes}
                      />

                      <small className="text-muted">
                        {NotesCharacterCount - values.notes.length}{" "}
                        {values.notes.length > 500
                          ? "characters"
                          : "characters left"}
                      </small>
                      <Form.Control.Feedback type="invalid">
                        {errors.notes}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <AutoSubmitRepsonse
                      response={questionResponse}
                      onAnswered={onAnswered}
                      updateQResponse={setQuestionResponse}
                      questionId={questionId}
                      assessmentId={assessmentId}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <div className="mt-3 mb-3 text-end">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className={`px-4 ${!previous && "disabled"}`}
                        as={Link}
                        to={previous}
                        disabled={!previous}
                      >
                        Previous
                      </Button>{" "}
                      {next ? (
                        <Button
                          variant="primary"
                          size="sm"
                          className={`px-4 ${
                            !next || (!isValid && "disabled")
                          }`}
                          as={Link}
                          to={next}
                          disabled={!next || !isValid}
                        >
                          Next
                        </Button>
                      ) : null}
                    </div>
                  </Card.Footer>
                </Card>

                <div className="mt-3 mb-3">
                  {questionResponse.responseId && (
                    <>
                      <small className="float-end">
                        Score:{" "}
                        {questionResponse.isInapplicable
                          ? "N/A"
                          : questionResponse.answerId
                          ? question.weight * questionResponse.score
                          : "N/A"}
                      </small>
                      <small className="float-end me-2">|</small>
                    </>
                  )}
                  <small className="float-end me-2">
                    Weight:{" "}
                    {question.weight
                      ? Math.round(question.weight * 100) / 100
                      : "1"}
                  </small>
                  <small>
                    Last updated at{" "}
                    {new Date(question.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>{" "}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
      <Col xs={12} md={4} className="scroller">
        <div className="mt-4 mb-5">
          {question.infoId && (
            <small>
              <h5>Helpful Information & Resources</h5>
              <hr />

              <ContentDetails contentId={question.infoId} />
            </small>
          )}
        </div>
      </Col>
    </Row>
  );
};

const AutoSubmitRepsonse = ({
  assessmentId,
  questionId,
  onAnswered,
  updateQResponse,
}) => {
  const subscribedPromises = useRef([]);

  const { values, initialValues, submitForm } = useFormikContext();

  const updateResponse = (values) => {
    const questionResponsePromise = post(`responses`, {
      assessmentId: assessmentId,
      questionId: questionId,
      answerId: values.selectedAnswer,
      isInapplicable: !!values.isInapplicable,
      notes: values.notes,
    });

    questionResponsePromise.promise
      .then((response) => {
        let qResponse = response.data;
        updateQResponse(qResponse);
        onAnswered(qResponse);
      })

      .catch((error) => {
        console.log(error);
      });
    subscribedPromises.current.push(questionResponsePromise);
  };

  const submitResponse = useRef(
    debounce((values) => {
      updateResponse(values);
    }, 1000)
  );

  useEffect(() => {
    if (values !== initialValues) {
      submitResponse.current(values);
      const promises = subscribedPromises.current;
      return () => {
        promises.forEach(function (promise) {
          promise.cancel();
        });
      };
    }
  }, [values, initialValues, submitForm]);
  return null;
};

Question.propTypes = {
  questionId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  onAnswered: PropTypes.func.isRequired,
  findNextAndPrevious: PropTypes.func.isRequired,
};

export default Question;
