import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { get } from "utils/DeApi";
import { ProgressBar } from "react-bootstrap";
import ReportScore from "../ReportScore/ReportScore";

const AssessmentSummary = ({ assessmentId, lensId }) => {
  const subscribedPromises = useRef([]);
  const [lens, setLens] = useState();
  const [responses, setResponses] = useState();

  useEffect(() => {
    const fetchResponses = () => {
      const responsesPromise = get(`assessments/${assessmentId}/responses`);
      responsesPromise.promise
        .then((results) => {
          setResponses(results.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            console.warn(error);
          }
        });
      subscribedPromises.current.push(responsesPromise);
    };
    fetchResponses();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [assessmentId]);

  useEffect(() => {
    const fetchLens = () => {
      const lensPromise = get(`lenses/${lensId}`);
      lensPromise.promise
        .then((response) => {
          setLens(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            console.warn(error);
          }
        });
      subscribedPromises.current.push(lensPromise);
    };
    fetchLens();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [lensId]);

  if (!lens || !responses) return <span />;

  var lensQuestions = [
    ...new Set(lens.sections.map(({ questions }) => questions).flat()),
  ].filter(
    (q, index, self) =>
      self.findIndex((item) => item.questionId === q.questionId) === index
  );

  var questionsWithResponse = lensQuestions.filter((q) =>
    responses.find(
      (r) => r.questionId === q.questionId && (r.answerId || r.isInapplicable)
    )
  );

  var noOfQuestions = lensQuestions.length;
  var noOfCompletedQuestions = questionsWithResponse.length;

  const sections = lens.sections.map((section) => {
    section["score"] = 0;
    section["maxScore"] = 0;
    section.questions.forEach((question) => {
      const qResponse = responses.find(
        (response) => response.questionId === question.questionId
      );

      const weight = question.weight || 1;
      section["score"] +=
        qResponse && qResponse.isInapplicable
          ? 0
          : qResponse && qResponse.answerId
          ? qResponse.score * weight
          : 0;

      section["maxScore"] +=
        qResponse && qResponse.isInapplicable
          ? 0
          : Math.max(...question.answers.map((answer) => answer.score)) *
            weight;
    });
    return section;
  });

  var principles = [];
  var sectionByPrinciple = {};

  sections.forEach((section) => {
    section.principles.forEach((p) => {
      sectionByPrinciple[p.principleId] = sectionByPrinciple[p.principleId]
        ? [...sectionByPrinciple[p.principleId], ...[section]]
        : [section];

      if (
        !principles.find((principle) => principle.principleId === p.principleId)
      )
        principles = [...principles, ...[p]];
    });
  });

  principles = principles.map((principle) => {
    let pSections = sectionByPrinciple[principle.principleId];
    principle["score"] = 0;
    principle["maxScore"] = 0;

    pSections.forEach((section) => {
      principle["score"] += section.score;
      principle["maxScore"] += section.maxScore;
    });
    return principle;
  });

  return (
    <>
      <span className="text-nowrap d-flex flex-row">
        <span className="me-4">
          <p className="mb-2">
            <small>Completion</small>
          </p>
          <p className="mb-2">
            <small>
              {noOfCompletedQuestions} of {noOfQuestions} questions answered.
            </small>
          </p>
          <span>
            <ProgressBar
              style={{ backgroundColor: "#e0e6f1", height: "12px" }}
              now={(noOfCompletedQuestions / noOfQuestions) * 100}
            />
          </span>
        </span>
        {principles.map((principle) => (
          <span key={principle.principleId} className="text-center me-2">
            <small
              role="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={principle.title}
            >
              {principle.title.length > 12
                ? principle.title.substring(0, 9) + "..."
                : principle.title}
            </small>

            <ReportScore
              score={
                principle.maxScore
                  ? (principle.score / principle.maxScore) * 100
                  : 0
              }
              size={"sm"}
            />
          </span>
        ))}
      </span>
    </>
  );
};

AssessmentSummary.propTypes = {
  lensId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
};

export default AssessmentSummary;
