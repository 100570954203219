import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";

import { post, get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const ProductCreate = ({ organization, onProductCreated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [lenses, setLenses] = useState();
  const [products, setProducts] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let DescriptionCharacterCount = 255;

  const schema = yup.object().shape({
    lens: yup.string().required(),
    assessmentName: yup
      .string()
      .min(3, "Too Short!")
      .max(100, "Too Long!")
      .required("Required.!"),
    product: yup.string(),
    name: yup.string().min(3, "Too Short!").max(100, "Too Long!"),
    description: yup
      .string()
      .max(255, "Description should not exceed 255 characters!"),
  });

  const createAssessment = (product, lensId, assessmentName) => {
    setError("");
    setIsLoading(true);
    let lens = lenses.find((lens) => lens.lensId === lensId);
    const assessmentPromise = post(`/assessments`, {
      productId: product.productId,
      lensId: lens.lensId,
      name: assessmentName,
    });
    assessmentPromise.promise
      .then((response) => {
        var assessment = response.data;
        setError(null);
        setIsLoading(false);
        handleClose();
        onProductCreated(product, assessment);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(assessmentPromise);
  };

  const createProduct = (values) => {
    setError("");
    setIsLoading(true);

    const productPromise = post(`products`, {
      name: values.name,
      description: values.description,
      organizationId: organization.categoryId,
    });
    productPromise.promise
      .then((response) => {
        let product = response.data;
        setError(null);
        setIsLoading(false);
        createAssessment(product, values.lens, values.assessmentName);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(productPromise);
  };

  useEffect(() => {
    const fetchProducts = () => {
      setError("");
      setIsLoading(true);
      const productsPromise = get("products", {
        params: { organizationId: organization.categoryId },
      });
      productsPromise.promise
        .then((response) => {
          const products = response.data;
          setIsLoading(false);
          setError(null);
          setProducts(products);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(productsPromise);
    };
    const fetchLenses = () => {
      setError("");
      setIsLoading(true);

      const lensesPromise = get("lenses", {
        params: { organizationId: organization.categoryId },
      });
      lensesPromise.promise
        .then((response) => {
          const lenses = response.data;

          setIsLoading(false);
          setError(null);
          setLenses(lenses);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(lensesPromise);
    };

    fetchProducts();
    fetchLenses();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization]);

  if (!lenses || !lenses.length) return <span />;

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        New Product Assessment
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Product Assessment</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            if (values.product) {
              let product = products.find(
                (item) => item.productId === values.product
              );
              createAssessment(product, values.lens, values.assessmentName);
            } else createProduct(values);
          }}
          initialValues={{
            lens: lenses[0].lensId,
            assessmentName: "",
            name: "",
            description: "",
            product: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="assessmentNae">
                  <Form.Label>Assessment Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="assessmentName"
                    value={values.assessmentName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.assessmentName && !errors.assessmentName}
                    isInvalid={!!errors.assessmentName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.assessmentName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="lens" className="mt-3 mb-3">
                  <Form.Label>Select an Assessment Model</Form.Label>
                  <Field
                    as={Form.Select}
                    name="lens"
                    value={values.lens}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.lens && !errors.lens}
                  >
                    {lenses.map((lens) => (
                      <option key={lens.lensId} value={lens.lensId}>
                        {lens.title}
                      </option>
                    ))}
                  </Field>
                </Form.Group>

                {products && !!products.length && (
                  <Form.Group controlId="lens" className="mt-3 mb-3">
                    <Form.Label>Select a Product</Form.Label>
                    <Field
                      as={Form.Select}
                      name="product"
                      defaultValue={""}
                      value={values.product}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        (values.product || values.name) && !errors.product
                      }
                    >
                      <option value={""} selected>
                        New Product
                      </option>
                      {products.map((product) => (
                        <option
                          key={product.productId}
                          value={product.productId}
                        >
                          {product.name}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>
                )}
                {!values.product && (
                  <>
                    <hr />
                    <Form.Group controlId="name" className="mt-3 mb-3 ms-3">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.name && !errors.name}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      controlId="description"
                      className="mt-3 mb-3 ms-3"
                    >
                      <Form.Label>
                        Product Description <small>Optional</small>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={3}
                        isInvalid={!!errors.description}
                      />

                      <small className="text-muted">
                        {DescriptionCharacterCount - values.description.length}{" "}
                        {values.description.length > 255
                          ? "characters"
                          : "characters left"}
                      </small>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={
                    !values.assessmentName ||
                    (!values.name && !values.product) ||
                    !isValid
                  }
                >
                  Create Product Assessment
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ProductCreate;
