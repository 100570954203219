import React, { useEffect, useRef } from "react";
import "./ReportChart.scss";
import * as echarts from "echarts";

const ReportChart = ({ data }) => {
  const el = useRef(null);
  useEffect(() => {
    const option = {
      grid: {
        left: "3%",
        right: "4%",
        top: "4%",
        bottom: "13%",
        containLabel: true,
      },
      legend: { data: ["Baseline"], right: "0%", bottom: "0%" },
      color: [
        "#5470c6",
        "#91cc75",
        "3fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ],
      yAxis: {
        type: "category",
        data: data.map((item) => item.name),
        axisLabel: {
          color: "gray",
          fontSize: "10",
        },
      },
      xAxis: {
        type: "value",
        min: 0,
        max: 100,
      },
      tooltip: {
        trigger: "item",
      },
      visualMap: {
        orient: "horizontal",
        left: "left",
        min: 0,
        max: 100,
        text: ["High Score", "Low Score"],
        // Map the score column to color
        dimension: 0,
        inRange: {
          color: ["#FD665F", "#FFCE34", "#65B581"],
        },
      },
      series: [
        {
          type: "bar",
          data: data.map((item) => item.score.toFixed(2)),
          name: "Actual",
          colorBy: "data",
        },
        {
          type: "line",
          data: data.map((item) => item.benchmark),

          name: "Baseline",
          symbol: "path://M0,0 M5,0 V 320 M10,10",
          // symbol: "path://M0,0 M4,0 V 320 h1 V-320 h-1 V160  M10,0",
          symbolSize: 48,
          lineStyle: { width: 0 },
          itemStyle: {
            borderWidth: 2,
            borderColor: "black",
            color: "black",
          },
        },
      ],
    };
    const chart = echarts.init(el.current);
    chart.setOption(option);
  }, [el, data]);

  return (
    <div className="ReportChart">
      <div className="report-chart-container mx-auto" ref={el} />
    </div>
  );
};

export default ReportChart;
