import Footer from "components/App/Footer/Footer";
import React from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";

import Header from "../App/Header/Header";
import NoMatch from "../App/NoMatch/NoMatch";
import AllProducts from "./AllProducts/AllProducts";
import ProductDetails from "./ProductDetails/ProductDetails";

const Product = () => {
  return (
    <>
      <Header title="" />
      <Container className="mb-5 mt-3 MainContent">
        <Switch>
          <Route
            exact
            path="/products/:id"
            component={(props) => (
              <ProductDetails {...props} productId={props.match.params.id} />
            )}
          />
          <Route path="/products" component={AllProducts} />
          <Route component={NoMatch} />
        </Switch>
      </Container>
      <Footer />
    </>
  );
};

Product.propTypes = {};

export default Product;
