import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Collapse, Nav } from "react-bootstrap";

const SectionNavigation = ({ assessment, section }) => {
  const [open, setOpen] = useState(false);
  var location = useLocation();

  useEffect(() => {
    setOpen(
      location.pathname.includes(
        `/assessments/${assessment.assessmentId}/sections/${section.sectionId}`
      )
    );
  }, [location.pathname, section.sectionId, assessment.assessmentId]);

  let sectionScore = 0;
  let qAnswered = 0;

  section["maxScore"] = 0;
  section.questions.forEach((question) => {
    const qResponse = assessment.responses.find(
      (response) => response.questionId === question.questionId
    );

    const weight = question.weight || 1;

    section["maxScore"] +=
      qResponse && qResponse.isInapplicable
        ? 0
        : Math.max(...question.answers.map((answer) => answer.score)) * weight;

    sectionScore +=
      qResponse && qResponse.isInapplicable
        ? 0
        : qResponse
        ? weight * qResponse.score
        : 0;

    qAnswered =
      qResponse && (qResponse.answerId || qResponse.isInapplicable)
        ? ++qAnswered
        : qAnswered;
  });

  return (
    <div className="py-1 my-0 w-100 ">
      <div className="d-flex" onClick={() => setOpen(!open)}>
        <span className="align-self-baseline">
          {open ? (
            <span className="material-icons-outlined">expand_more</span>
          ) : (
            <span className="material-icons-outlined">chevron_right</span>
          )}
        </span>
        <h4 className="align-self-baseline flex-grow-1" role="button">
          {section.title}
        </h4>

        <span className="align-content-end align-self-baseline text-nowrap text-muted">
          <span title={`Final Weighted Score for ${section.title}`}>
            {Math.round(sectionScore)}
          </span>
          <small>/{Math.round(section.maxScore)} points</small>
        </span>
      </div>
      <Collapse in={open}>
        <Nav defaultActiveKey={location.pathname} variant="pills">
          {section.questions.map((question, index) => {
            var href = `/assessments/${assessment.assessmentId}/sections/${section.sectionId}/questions/${question.questionId}`;
            var qResponse = assessment.responses.find(
              (response) => response.questionId === question.questionId
            );
            return (
              <Nav.Link
                key={section.sectionId + "" + question.questionId}
                as={Link}
                to={href}
                active={location.pathname === href}
                className="w-100"
              >
                {qResponse &&
                  (qResponse.answerId || qResponse.isInapplicable) && (
                    <span className="material-icons float-end">done</span>
                  )}
                {++index}. {question.title}
              </Nav.Link>
            );
          })}
        </Nav>
      </Collapse>
    </div>
  );
};

SectionNavigation.propTypes = {
  assessment: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
};

export default SectionNavigation;
