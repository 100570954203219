import React, { useState } from "react";
import PropTypes from "prop-types";

import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import ReportChart from "../ReportChart/ReportChart";
import ReportScore from "../ReportScore/ReportScore";

import {
  Row,
  Col,
  Card,
  ProgressBar,
  Dropdown,
  Tab,
  Nav,
  Breadcrumb,
} from "react-bootstrap";
import AssessmentUpdate from "../AssessmentUpdate/AssessmentUpdate";
import AssessmentDelete from "../AssessmentDelete/AssessmentDelete";
import { useHistory } from "react-router";
import Loader from "components/Loader/Loader";

const AssessmentReport = ({ lens, assessment, principles }) => {
  const [key, setKey] = useState(principles?.[0]?.title);

  let history = useHistory();
  const handleAssessmentDeleted = () => {
    history.push("/");
  };

  const handleAssessmentUpdated = () => {
    history.go(0);
  };

  if (!lens || !assessment) return <Loader />;

  var lensQuestions = [
    ...new Set(lens.sections.map(({ questions }) => questions).flat()),
  ].filter(
    (q, index, self) =>
      self.findIndex((item) => item.questionId === q.questionId) === index
  );

  var responses = assessment.responses || [];
  var questionsWithResponse = lensQuestions.filter((q) =>
    responses.find(
      (r) => r.questionId === q.questionId && (r.answerId || r.isInapplicable)
    )
  );
  var inapplicableQuestions = lensQuestions.filter((q) =>
    responses.find((r) => r.questionId === q.questionId && r.isInapplicable)
  );

  var noOfQuestions = lensQuestions.length;
  var noOfCompletedQuestions = questionsWithResponse.length;

  var totalMaxScore = lensQuestions
    // .filter((q) =>
    //   inapplicableQuestions.find((item) => item.questionId !== q.questionId)
    // )
    .reduce((sum, question) => {
      return (
        sum +
        Math.max(...question.answers.map((answer) => answer.score)) *
          question.weight
      );
    }, 0);

  var totalScore = questionsWithResponse.reduce((sum, question) => {
    let answer = responses.find(
      (response) => response.questionId === question.questionId
    );
    let score =
      answer && answer.isInapplicable
        ? 0
        : answer
        ? answer.score * question.weight
        : 0;

    return score + sum;
  }, 0);

  const radarData = lens.sections
    .filter((section) =>
      section.principles.find((principle) => principle.title.includes(key))
    )
    .map((section) => {
      let score = 0;
      section.questions.forEach((question) => {
        let answer = responses.find(
          (response) => response.questionId === question.questionId
        );
        score +=
          answer && answer.isInapplicable
            ? 0
            : answer
            ? answer.score * question.weight
            : 0;
      });

      return {
        name: section.title,
        max: section.maxScore,
        score: (score / section.maxScore) * 100,
        benchmark: 75,
      };
    });

  return (
    <Row>
      <Col xs={8}>
        <div className="mt-4 mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Assessments</Breadcrumb.Item>
            <Breadcrumb.Item active>{assessment.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h2>{assessment.name} </h2>

          <small className="float-end">
            <Dropdown>
              <Dropdown.Toggle className="btn-sm pb-0 bg-primary bg-opacity-10 rounded-3 text-dark border-0">
                <span className="material-icons-outlined md-20">settings</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <AssessmentUpdate
                    assessment={assessment}
                    onAssessmentUpdated={handleAssessmentUpdated}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <AssessmentDelete
                    assessment={assessment}
                    onAssessmentDeleted={handleAssessmentDeleted}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </small>
          <p>
            Product: {assessment.product.name}
            {" | "}
            <span>
              Last updated:{" "}
              {new Date(assessment.product.updatedAt).toLocaleString()}
            </span>
          </p>
          <hr />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={4} className="mt-3 ">
                  <Card className="mb-2">
                    <Card.Body>
                      <p>Total Score (%)</p>
                      <hr />
                      <ReportScore
                        score={
                          totalScore && totalMaxScore
                            ? (totalScore / totalMaxScore) * 100
                            : 0
                        }
                        size={"lg"}
                      />
                    </Card.Body>
                  </Card>
                  <Card className="mb-2">
                    <Card.Body>
                      <p>Completion</p>
                      <hr />
                      <p>
                        <small>
                          {noOfCompletedQuestions} of {noOfQuestions} questions
                          answered.
                        </small>
                      </p>
                      <span className="mt-3 mb-3 ">
                        <ProgressBar
                          style={{ backgroundColor: "#e0e6f1", height: "12px" }}
                          now={(noOfCompletedQuestions / noOfQuestions) * 100}
                        />
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} sm={8} className="mt-3 mb-3">
                  <Card>
                    <Card.Body>
                      <p>Scores by Principles (%)</p>
                      <hr />
                      <div className="d-flex flex-wrap">
                        {principles.map((principle) => (
                          <div
                            className="text-center p-3 w-25 text-truncate"
                            key={principle.principleId}
                          >
                            <small
                              role="button"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={principle?.title}
                            >
                              {principle?.title}
                            </small>
                            <ReportScore
                              score={
                                principle?.maxScore
                                  ? (principle?.score / principle?.maxScore) *
                                    100
                                  : 0
                              }
                              size={"sm"}
                            />
                          </div>
                        ))}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} className="mt-3 mb-3">
                  <Card>
                    <Card.Body>
                      <p className="mb-3">Scores against Baseline</p>
                      <hr />
                      {/* Tab Panel Starts */}
                      <Tab.Container
                        defaultActiveKey={principles?.[0]?.title}
                        onSelect={(k) => setKey(k)}
                      >
                        <div className="overflow-auto">
                          <Col sm={12}>
                            <Nav className="d-flex flex-nowrap" variant="pills">
                              {principles.map((item, ind) => (
                                <Nav.Item key={ind}>
                                  <Nav.Link
                                    className="wd-7 fs-6 text-center text-truncate"
                                    role="button"
                                    key={item.id}
                                    eventKey={item.title}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={item.title}
                                  >
                                    {item.title}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                          </Col>
                        </div>
                        <Col sm={12}>
                          <Tab.Content className="p-2">
                            <Tab.Pane eventKey={key}>
                              <ReportChart data={radarData.reverse()} />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Tab.Container>
                      {/* Tab Panel Ends */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs={4} className="scroller mb-5">
        <div className="mt-4 mb-5">
          <h5 className="mb-3">{lens?.title}</h5>
          <hr />
          <ContentDetails contentId={lens?.infoId} />
        </div>
      </Col>
    </Row>
  );
};

AssessmentReport.propTypes = {
  lens: PropTypes.object.isRequired,
  assessment: PropTypes.object.isRequired,
  principles: PropTypes.array.isRequired,
};

export default AssessmentReport;
