import React from "react";
import "./Content.scss";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";

import Header from "../App/Header/Header";
import NoMatch from "../App/NoMatch/NoMatch";
import ContentDetails from "./ContentDetails/ContentDetails";

const Content = () => {
  return (
    <>
      <Header title="" />
      <Container className="mb-5 mt-3 MainContent">
        <Row>
          <Col xs={12} md={9}>
            <Card className="mt-3 mb-3 py-3">
              <Card.Body>
                <Switch>
                  <Route
                    path="/contents/:id"
                    render={(props) => (
                      <ContentDetails
                        {...props}
                        contentId={props.match.params.id}
                        view={"full"}
                      />
                    )}
                  />
                  <Route component={NoMatch} />
                </Switch>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Content.propTypes = {};

export default Content;
