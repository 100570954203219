import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik } from "formik";

import { Modal, Button, Form } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const AssessmentDelete = ({ assessment, onAssessmentDeleted }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const deleteAssessment = () => {
    setError("");
    setIsLoading(true);

    const assessmentPromise = destroy(`assessments/${assessment.assessmentId}`);

    assessmentPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onAssessmentDeleted(assessment);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(assessmentPromise);
  };

  return (
    <>
      <Button
        variant="outline-danger"
        className="w-100"
        size="sm"
        onClick={handleShow}
      >
        Delete
      </Button>
      <div onKeyDown={(e) => e.stopPropagation()}>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Delete Assessment <i>{assessment.name}</i>
            </Modal.Title>
          </Modal.Header>

          {assessment && (
            <Formik
              validationSchema={schema}
              initialValues={{
                name: "",
              }}
            >
              {({ handleChange, handleBlur, values, isValid, errors }) => (
                <Form>
                  <Modal.Body>
                    <p>Are you sure you want to delete this assessment?</p>

                    <Form.Group controlId="productName" className="mt-3 mb-3">
                      <Form.Label>
                        Please type the name of the assessment to confirm.
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          values.name === assessment.name && !errors.name
                        }
                      />
                    </Form.Group>
                    {error && <ErrorHandler error={error} />}
                    {isLoading && <Loader />}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      variant="danger"
                      onClick={deleteAssessment}
                      disabled={
                        values.name.trim() !== assessment.name || !isValid
                      }
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        </Modal>
      </div>
    </>
  );
};

AssessmentDelete.propTypes = {
  assessment: PropTypes.object.isRequired,
  onAssessmentDeleted: PropTypes.func.isRequired,
};

export default AssessmentDelete;
