import React, { useEffect, useRef } from "react";
import "./ReportScore.scss";
import * as echarts from "echarts";

const ReportScore = ({ score, size }) => {
  const el = useRef(null);

  useEffect(() => {
    const option = {
      color: ["#007A5F"],

      series: [
        {
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          progress: {
            show: true,
            width: 10,
          },
          min: 0,
          max: 100,
          axisLine: {
            lineStyle: {
              width: 10,
            },
          },
          pointer: { show: false },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          anchor: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            fontSize: 18,
          },
          data: [
            {
              value: score ? Math.round(score) : 0,
              detail: {
                fontWeight: "normal",
                offsetCenter: [0, 0],
                formatter: function (value) {
                  return `{a|${value.toFixed(0)}}`;
                },
                rich: {
                  a: {
                    fontSize: size === "sm" ? 16 : 18,
                    color: "#000",
                    lineHeight: 1,
                  },
                  b: {
                    fontSize: size === "sm" ? 12 : 12,
                    color: "#000",
                  },
                },
              },
            },
          ],
        },
      ],
    };
    const chart = echarts.init(el.current);
    chart.setOption(option);
  }, [el, score, size]);

  return (
    <div className="ReportScore">
      <div className={`report-score-container mx-auto ${size}`} ref={el} />
    </div>
  );
};

export default ReportScore;
