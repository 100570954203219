import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";

import Header from "../App/Header/Header";
import NoMatch from "../App/NoMatch/NoMatch";
import AssessmentDetails from "./AssessmentDetails/AssessmentDetails";
import { OrganizationContext } from "contexts/OrganizationProvider";
import Footer from "components/App/Footer/Footer";

const Assessment = () => {
  const organization = useContext(OrganizationContext);

  return (
    <>
      <Header title="Assessment Details" />
      <Container fluid className="MainContent">
        <Switch>
          <Route
            path="/assessments/:id"
            render={(props) => (
              <AssessmentDetails
                {...props}
                assessmentId={props.match.params.id}
                organization={organization}
              />
            )}
          />
          <Route component={NoMatch} />
        </Switch>
      </Container>
      <Footer />
    </>
  );
};

Assessment.propTypes = {};

export default Assessment;
