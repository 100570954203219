import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { get } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ProductCreate from "components/Product/ProductCreate/ProductCreate";
import AssessmentSummary from "components/Assessment/AssessmentSummary/AssessmentSummary";

const AssessmentsList = ({ organization }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [assessments, setAssessments] = useState();

  useEffect(() => {
    const fetchAssessments = () => {
      setError("");
      setIsLoading(true);
      const assessmentsPromise = get("assessments", {
        params: { organizationId: organization.categoryId },
      });
      assessmentsPromise.promise
        .then((response) => {
          const assessments = response.data;
          setIsLoading(false);
          setError(null);
          setAssessments(assessments);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(assessmentsPromise);
    };

    fetchAssessments();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  if (!assessments) return <span />;

  return (
    <>
      <span className="float-end">
        <ProductCreate
          organization={organization}
          onProductCreated={(product, assessment) => {
            setAssessments((prevAssessments) => [
              ...[assessment],
              ...prevAssessments,
            ]);
          }}
        />
      </span>
      <h4>Assessments</h4>
      <hr />

      {assessments && !assessments.length && (
        <h4 className="mt-3 mb-3">
          There are currently no assessments to show.
        </h4>
      )}

      {assessments &&
        assessments.map((assessment) => (
          <span key={assessment.assessmentId}>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <h4>
                  <Link
                    to={`/assessments/${assessment.assessmentId}`}
                    size="sm"
                    className="text-decoration-none bold"
                    variant="secondary"
                  >
                    {assessment.name}
                  </Link>
                </h4>
                <p className="mb-2">
                  <span className="me-3">
                    <span className="material-icons md-18 text-muted">
                      sell
                    </span>{" "}
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/products/${assessment.product.productId}`}
                    >
                      {assessment.product.name > 15
                        ? assessment.product.name.substring(0, 12) + "..."
                        : assessment.product.name}
                    </Link>
                  </span>

                  <span className="me-3">
                    <span className="material-icons md-18 text-muted">
                      policy
                    </span>{" "}
                    <span>
                      {" "}
                      {assessment.lens.title > 15
                        ? assessment.lens.title.substring(0, 12) + "..."
                        : assessment.lens.title}
                    </span>
                  </span>
                </p>
                <p>
                  <small>
                    Last updated at{" "}
                    {new Date(assessment.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </p>
              </div>
              <div className="float-end overflow-auto">
                <AssessmentSummary
                  assessmentId={assessment.assessmentId}
                  lensId={assessment.lens.lensId}
                />
              </div>
            </div>
            <hr />
          </span>
        ))}
    </>
  );
};

AssessmentsList.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default AssessmentsList;
