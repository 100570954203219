import React, { useEffect, useRef, useState, useCallback } from "react";
import "./ContentDetails.scss";
import { get } from "utils/BeeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const ContentDetails = ({ contentId, view }) => {
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    fetchContent(contentId);
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [contentId]);

  const setResponsiveness = useCallback((bodyNode) => {
    if (bodyNode !== null) {
      let aTags = bodyNode.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }

      let imgTags = bodyNode.getElementsByTagName("img");
      for (let img of imgTags) {
        img.setAttribute("class", "img-fluid");
      }

      let iframeTags = bodyNode.getElementsByTagName("iframe");
      for (let iframe of iframeTags) {
        iframe.removeAttribute("height");
        iframe.removeAttribute("width");
        let wrapper = document.createElement("div");
        wrapper.className = "ratio ratio-16x9";
        // insert wrapper before el in the DOM tree
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
      }

      let figureTags = bodyNode.getElementsByTagName("figureTags");
      for (let figure of figureTags) {
        figure.setAttribute("class", "img-fluid");
      }
    }
  }, []);

  function fetchContent(id) {
    if (id) {
      setIsLoading(true);
      setError("");

      const createPromise = get(`contents/${id}`, {});
      createPromise.promise
        .then((response) => {
          setContent(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(createPromise);
    } else {
      return null;
    }
  }
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!content) return <span />;

  return (
    <div>
      {view === "full" && (
        <>
          <h1>{content.title}</h1>
          <hr />
        </>
      )}
      <div
        className="ContentDetails"
        ref={setResponsiveness}
        dangerouslySetInnerHTML={{ __html: content.contentBody }}
      />
    </div>
  );
};

export default ContentDetails;
