import React, { createContext, useState, useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";

const OrganizationContext = createContext(undefined);
const OrganizationDispatchContext = createContext(undefined);

function OrganizationProvider({ children }) {
  const [organization, setOrganization] = useState();
  const user = useContext(UserContext);

  const dispatchContext = (org) => {
    let key = "b3JnYW5pemF0aW9u";
    let value = btoa(JSON.stringify(org));
    localStorage.setItem(key, value);
    setOrganization(org);
  };

  useEffect(() => {
    const cachedOrg = localStorage.getItem("b3JnYW5pemF0aW9u");
    const org = cachedOrg
      ? JSON.parse(atob(cachedOrg))
      : user
      ? user.categories.find((tag) =>
          tag.groups.find((group) => group.groupId === "6197b16d2dc39")
        )
      : null;
    setOrganization(org);
  }, [user]);

  if (organization === undefined) return <span />;

  return (
    <OrganizationContext.Provider value={organization}>
      <OrganizationDispatchContext.Provider value={dispatchContext}>
        {children}
      </OrganizationDispatchContext.Provider>
    </OrganizationContext.Provider>
  );
}

export {
  OrganizationProvider,
  OrganizationContext,
  OrganizationDispatchContext,
};
