import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.css";

import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import NoMatch from "./NoMatch/NoMatch";

import Dashboard from "../Dashboard/Dashboard";
import Product from "components/Product/Product";
import Assessment from "components/Assessment/Assessment";
import Content from "components/Content/Content";

import { UserProvider, UserContext } from "contexts/UserProvider";
import { OrganizationProvider } from "contexts/OrganizationProvider";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
const App = () => {
  return (
    <div className="Main">
      <Router>
        <ScrollToTop />
        <UserProvider>
          <OrganizationProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/products" component={Product} />
              <PrivateRoute path="/assessments" component={Assessment} />
              <PrivateRoute path="/contents" component={Content} />
              <Route path="/logout" component={Logout} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route component={NoMatch} />
            </Switch>
          </OrganizationProvider>
        </UserProvider>
      </Router>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const user = useContext(UserContext);

  if (!user)
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export default App;
