import React, { useContext } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";

import Feedback from "../Feedback/Feedback";
import {
  OrganizationContext,
  OrganizationDispatchContext,
} from "contexts/OrganizationProvider";
import { UserContext } from "contexts/UserProvider";

const Header = ({ title }) => {
  const user = useContext(UserContext);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"ERMClarity - " + title || ""}</title>
      </Helmet>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/logo.svg"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />{" "}
            ERMClarity
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav>
              <OrganizationToggler />
            </Nav>
            <Nav className="ms-auto">
              <Feedback />

              <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
                <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

function OrganizationToggler() {
  // The Organization Toggler receives not only the organization
  // but also a toggleOrganization function from the context

  const organization = useContext(OrganizationContext);
  const setOrganization = useContext(OrganizationDispatchContext);
  const user = useContext(UserContext);

  const organizations = user.categories.filter((tag) =>
    tag.groups.find((group) => group.groupId === "6197b16d2dc39")
  );
  return (
    <NavDropdown
      title={<span className="text-dark">{organization.categoryName}</span>}
      className="bg-primary bg-opacity-10 rounded-3 px-1"
    >
      {organizations.map((org) => (
        <NavDropdown.Item
          onClick={() => setOrganization(org)}
          key={org.categoryId}
          active={org.categoryId === organization.categoryId}
        >
          {org.categoryName}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
